export const en = {
  login: {
    "App Store 下载": "App Store",
    "Google Play 下载": "Google Play",
    注册: "Register Now",
    title: "Buy Crypto with <br/>Visa/Master Credit Card!",
    title2: "14000USDT",
    title3: "Exclusive for New Users",
    li1: "Founded in 2011, BTCC is the world's longest-running <br/> crypto exchange",
    li1_1: "Founded in 2011, BTCC is the world's longest-running crypto exchange",
    li2: "US MSB and CA MSB licensed",
    li3: "18+ cryptocurrencies available ",
    li4: "Allows for deposits in 9+ fiat currencies",
    li5: "Trade with 1 - 150 leverage ",
    li6: "Meet the deposit targets within 7 days after successful registration at BTCC, and you can enjoy the bonus of the corresponding target levels.",
    tab1: "Email ",
    email: "Please enter your email address",
    code: "Please enter the verification code",
    Send: "Get code",
    pwd: "Password must be 6–16 characters containing both numbers and letters.",
    tab2: "Mobile",
    mobile: "Please enter your mobile number",
    Register: "Register Now",
    服务条款: "https://www.btcc.com/en-US/detail/142641",
    隐私声明: "https://www.btcc.com/en-US/detail/142638",
    风险提示: "https://www.btcc.com/en-US/detail/149036",
    and: "and",
    "首页url":"https://lp.btcc.academy/en-GB",
    "首页":"Home",
    "关于我们":"About Us",
    "about":"en-GB/about-us.html",
    "about_in":"about_in-us.html",
    "about_dm":"about_dm-us.html",
    "about_dms":"download/about_dm-kr.html",
    "about_coupon":"about_coupon-us.html",
    "about_rebate":"about_rebate-us.html",
    "about_lottery":"about_lottery-us.html",

    mobileTip: "Please enter a valid mobile number",
    emailTip: "Please enter a valid email address",
    codeTip: "Please enter a valid verification code",
   pwdTip:
      "Password must be 8-16 characters long with both uppercase and lowercase letters and numbers special characters.",
    tips: "I have read and agree to BTCC's",
    tips1: "《User agreement》",
    tips2: "《Privacy policy》",
    tips3: "《Risk Disclosure》",

    registeSuccessInfo: "Thank you for your registration. ",
    startTrading: "Start trading now!",
    registeSuccessInfoPhone: "Thank you for your registration. ",
    startTradingPhone: "Start trading now!",
    注册成功:"Registration successful",
    下载应用:"Download the app to get your <br/><span>10 USDT</span> bonus!",
    立即下载:"Download now"
  },
  ad_sign_up:{
    邮箱注册:"Email Registration",
    电子邮箱:"Email address",
    验证码:"Verification code",
    获取验证码:"Get Verification Code",
    设置密码:"Create a password",
    立即注册:"Register now",
    手机注册:"Mobile Registration",
    手机号码:"Phone number",
      tips1: "《User agreement》",
      tips2: " 《Privacy Agreement》",
      tips3: " 《Risk Disclosure》",
  },

  BitcoinHalvingBTCC: {
    与BTCC一起见证比特币减半: "Witness the Bitcoin Halving<br/> with BTCC <h1>Grab a share of<br/> 13 BTC</h1>",
    活动时间: "Campaign period: From March 1, 2024, 02:00:00 (UTC) until the Bitcoin block height reaches over 840,000",
    当前区块高度: "Current Block Height",
    剩余区块: "Blocks Remaining",
    减半区块: "Halving Block Height",
    预估减半时间: "Estimated Halving Date",
    立即报名: "Register now",
    已报名: "Registered",
    统计发奖中: "Campaign ended. Distributing rewards    ",
    用户获得: "User{{uid}} earned<span> {{awardAmount}} SATS</span>",
    比例膨胀: "As the Bitcoin block height reaches the following milestones, the reward amount in your account will grow according to the corresponding rates:  ",
    区块高度: "Block Height Milestone",
    膨胀比例: "Reward Growth Rate  ",
    膨胀奖励计算方法: "An example on how rewards grow: For instance, when the Bitcoin block height reaches 835,000, User A's already earned 10,000 SATS reward will grow to 10,200 SATS (10,000 SATS × 1.02). When the block height reaches 836,000, the reward will grow to 10,710 SATS (10,200 SATS × 1.05).",
    当前我获得的奖励: "Current Reward Earned",
    换算器: "SATS/BTC/USDT Converter",
    活动奖励: "Campaign Rewards",
    签到奖: "Check-in Reward",
    每日签到: "Earn 100 SATS for each daily check-in.",
    去签到: "Check in now",
    已签到: "Checked in today",
    交易奖: "Trading Reward",
    每交易: "Earn 500 SATS for every 10,000 USDT traded.",
    不满: "(Trade volume of less than 10,000 USDT will not be calculated for rewards. Each participant can earn a maximum of 10,000,000 SATS.)  ",
    去交易: "Trade now",
    充值奖: "Deposit Reward",
    每充值: "Earn 10,000 SATS for every 1,000 USDT deposited. ",
    不满1000: "(Deposits of less than 1,000 USDT will not be calculated for rewards. Each participant can earn a maximum of 10,000,000 SATS.)",
    去充值: "Deposit now",
    邀请奖: "Referral Reward  ",
    邀请好友: "Earn 10,000 SATS for each friend you invite who completes their first deposit.",
    每人最多: "(Each participant can earn a maximum of 10,000,000 SATS.)  ",
    去邀请: "Invite now",
    问答奖: "Quiz Reward",
    参与知识问答: "Participate in quizzes to earn 1,000 SATS per completed quiz. ",
    每当区块高度: "Unlock a quiz opportunity each time the Bitcoin block height reaches a milestone.  ",
    去答题: "Take a quiz  ",
    已完成: "Quiz completed",
    一题: "The Bitcoin halving this year is the _______ halving in history.  ",
    第二次: "second",
    第三次: "third",
    第五次: "fifth",
    第四次: "fourth",
    二题: `What does the "halving" in Bitcoin halving refer to? `,
    比特幣的價值減半: "The value of Bitcoin being halved",
    比特幣持有人數的減半: "The number of Bitcoin holders being halved",
    比特幣總量的減半: "The total quantity of Bitcoins being halved  ",
    比特幣區塊獎勵的減半: "The reward for Bitcoin mining being halved",
    三题: "What are the founding year of BTCC and the year of the first Bitcoin halving, respectively?",
    三A: "2012、2012",
    三B: "2012、2011",
    三C: "2011、2011",
    三D: "2011、2012",
    四题: "Which of the following is(are) the effect(s) of the Bitcoin halving?",
    供應速度減慢: "A slowdown in the rate at which new bitcoins are created",
    比特幣礦工利潤變動: "Changes in Bitcoin miner profits",
    比特幣礦工更加競爭: "Increased competition among Bitcoin miners",
    以上皆是: "All of the above",
    五题: `What is the social media hashtag for the "BTCC x Bitcoin" campaign? `,
    BTCCxBitcoin: "#BTCCxBitcoin",
    BTCCBitcoin: "#BTCC&Bitcoin",
    BTCONBTCC: "#BTCONBTCC",
    BitcoinOnBTCC: "#BitcoinOnBTCC",
    排行榜: "Leaderboard",
    排行榜排名: "The leaderboard ranks participants based on the amount of rewards earned. The top 10 participants will receive <span>100,000</span> SATS each, and those ranking 11th to 50th will receive <span>10,000</span> SATS each. The leaderboard shows only the top 10 participants.",
    我的排名: "My Ranking:",
    未上榜: "Not on list  ",
    排名: "Ranking",
    用户UID: "User ID  ",
    奖励金额: "Reward Amount (SATS)  ",
    null:"Not on list",
    0: "Not on list",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    13: "13",
    14: "14",
    15: "15",
    16: "16",
    17: "17",
    18: "18",
    19: "19",
    20: "20",
    21: "21",
    22: "22",
    23: "23",
    24: "24",
    25: "25",
    26: "26",
    27: "27",
    28: "28",
    29: "29",
    30: "30",
    31: "31",
    32: "32",
    33: "33",
    34: "34",
    35: "35",
    36: "36",
    37: "37",
    38: "38",
    39: "39",
    40: "40",
    41: "41",
    42: "42",
    43: "43",
    44: "44",
    45: "45",
    46: "46",
    47: "47",
    48: "48",
    49: "49",
    50: "50",
    进行排名: "*Tiebreakers for equal reward amounts will be determined by the order of registration, from earliest to latest.",
    活动规则: "Campaign Rules",
    规则1: "1. Registration is required to participate, and only users who have completed KYC verification are eligible.",
    规则2: "2. The campaign ends when the Bitcoin block height reaches 840,000.",
    规则3: "3. If a user registers after a block height milestone has passed, rewards will not grow based on the growth rate of that milestone. Participants must wait for the next milestone.  ",
    规则4: "4. A quiz opportunity is unlocked at each block height milestone reached. If the previous quiz is not completed before the next one is unlocked, the previous quiz will expire.",
    规则5: "5. Campaign rewards will ultimately be converted and distributed in USDT.",
    规则6: "6. The actual distributed rewards will be in the form of trading funds, with the actual amount based on the BTC price at the time of settlement.  ",
    规则7: "7. Reward amounts will be settled and distributed within 7 business days after the campaign ends.",
    规则8: "8. BTCC reserves the right to disqualify participants who engage in malicious behavior to profit, including but not limited to opening multiple accounts for additional trading funds, reusing on-chain funds, and other suspected illegal, fraudulent, or harmful actions.",
    规则9: "9. BTCC holds the final interpretation right of this campaign. We reserve the right to adjust the content and rewards of the campaign according to market conditions without notifying users. For any questions, please contact our customer service team.  ",
    回答错误: "Incorrect answer. Please try again.  ",
    提交答案: "Submit answer",
    请先完成报名: "Please register first",
    请先完成KYC: "Please complete KYC first  ",
    签到成功: "Successful check-in +100 SATS",
    登录查看: "Log in to view",
    回答正确: " Correct answer +1,000 SATS",

  },

  Foot: {
    text: "Risk warning：Digital asset trading is a new industry with bright prospects. It is trendy but also risky. Especially in leveraged trading, leveraging multiples simultaneously expand profits and amplify risks. Please make sure you have a good understanding of the industry, leveraged trading models, and trading rules before participating. We strongly recommend that you trade within your own risk. Trading is risky. You must be cautious when entering the market!",
    text2:
      "The world’s longest-running cryptocurrency exchange since 2011 © 2011-2022 BTCC.com. All rights reserved",
    服務: "Service",
    永續合約: "Perpetual Contract",
    當週合約: "Weekly Contract",
    當日合約: "Daily Contract",
    支持: "Support",
    最新公告: "Announcements",
    帮助中心: "Support",
    費率標準: "Fees",
    即時數據: "Real-time trading data",
    條款: "Terms",
    服務條款: "User agreement",
    隱私聲明: "Privacy policy",
    風險提示: "Risk Disclosure",
    客戶服務: "Customer Service",
    在线客服: "Online Customer Service",
    社區: "Community",
    全球社區: "200,000+ users joined our global community",
    快速鏈接: "Quick Links",
    如何在BTCC買賣比特幣合約: "Buy Bitcoin (BTC) Futures",
    如何在BTCC買賣以太坊期貨: "Buy Ethereum (ETH) Futures",
    如何在BTCC買賣萊特幣期貨: "Buy Litecoin (LTC) Futures",
    如何在BTCC買賣BCH比特幣現金: "Buy Bitcoin Cash (BCH) Futures",
    如何在BTCC買賣EOS柚子幣期貨: "Buy EOS Futures",
    如何在BTCC買賣瑞波幣XRP永續合約: "Buy Ripple (XRP) Futures",
    如何在BTCC買賣艾達幣ADA永續合約: "Buy Cardano (ADA) Futures",
    如何在BTCC買賣恆星幣XLM永續合約: "Buy Stellar (XLM) Futures",
    如何在BTCC買賣達世幣永續合約: "Buy Dash Futures",
    客服: "https://btccoverseas.s5.udesk.cn/im_client/?web_plugin_id=179&group_id=139&language=en-us&platform=GW",
    telegram: "https://t.me/youtbtcc",
    weibo: "https://weibo.com/p/1006062149945883/home",
    youtube: "https://www.youtube.com/channel/UCtP3iL99jxLT4a3xmI2g6dQ",
    twitter: "https://twitter.com/YourBTCC",
    facebook: "https://www.facebook.com/yourbtcc/",
    linkedin: "https://www.linkedin.com/company/yourbtcc/",
    instagram: "https://www.instagram.com/btccbitcoin",
    btccblog: "https://medium.com/btccblog",
    reddit: "https://www.reddit.com/r/YourBTCC/",
    比特幣合約: "https://www.btcc.com/academy/crypto-basics/guide-how-to-trade-bitcoin-futures-on-btcc",
    以太坊期貨: "https://www.btcc.com/academy/crypto-basics/guide-how-to-trade-ethereum-eth-futures-on-btcc",
    萊特幣期貨: "https://www.btcc.com/academy/crypto-basics/how-to-trade-ltc-litecoin-futures-on-btcc",
    比特幣現金: "https://www.btcc.com/academy/crypto-basics/how-to-trade-bitcoin-cash-bch-futures-on-btcc",
    柚子幣期貨: "https://www.btcc.com/academy/crypto-basics/how-to-trade-eos-futures-on-btcc",
    XRP永續合約: "https://www.btcc.com/academy/crypto-basics/how-to-trade-ripple-xrp-futures-on-btcc",
    ADA永續合約: "https://www.btcc.com/academy/crypto-basics/how-to-trade-cardano-ada-futures-on-btcc",
    XLM永續合約: "https://www.btcc.com/academy/crypto-basics/how-to-trade-stellar-xlm-futures-on-btcc",
    達世幣永續合約: "https://www.btcc.com/academy/crypto-basics/how-to-trade-dash-futures-on-btcc",
    "wallets": "Wallets",
    "wallet_balance": "Balance",
    "usdt_futures": "USDT-M Perpetual Futures",
    "coin_margined_futures": "Coin-M Perpetual Futures",
    "phone": "Mobile Phone",
    "email": "Email Address",
    "google": "Google Authenticator",
    "buy_crypto": "Fiat Deposit",
    "deposit_crypto": "Crypto Deposit",
    "menu_buy_crypto": "Fiat Deposit",
    "menu_deposit_crypto": "Crypto Deposit",
    "lever_trading": "Futures",
    "coin_convert": "Convert",
    "academy": "BTCC Academy",
    "markets": "Markets",
    "coin_news": "News",
    "more": "More",
    "downloads": "Downloads",
    "affiliate": "Affiliates",
    "vip_programme": "VIP Programme",
    "promotions": "Promotions",
    "promotions_center": "Campaigns",
    "referral_program": "Referral",
    "announcements": "Announcements",
    "support_center": "Support Centre",
    "trade": "Trade",
    "about_us": "About Us",
    "about_btcc": "About BTCC",
    "btcc_updates": "BTCC Updates",
    "feature_releases": "Feature Releases",
    "company_news": "Company News",
    "blog": "Blog",
    "usd_contract": "USDT-Margined Futures",
    "currency_contract": "Coin-Margined Futures",
    "fee": "Fee Discounts",
    "backtop": "Back to top",
    "online": "Online Customer Support",
    "login": "Login",
    "register": "Register",
    "register_now": "Register now",
    "login_now": "Log in",
    "services": "Services",
    "faq": "FAQ",
    "new_guide": "Beginner's guide",
    "download": "Download",
    "scan_download": "Scan to Download",
    "scan_download_app": "Scan to Download App",
    "download_app_title": "Trade anytime, anywhere",
    "know_more": "More",
    "knowledge": "About Trading",
    "tag_cloud": "Tags",
    "product": "Products",
    "guide": "Guides",
    "perpetual_contract": "Perpetual Futures",
    "weekly_contract": "Weekly Futures",
    "daily_contract": "Daily Futures",
    "quarter_contract": "Quarterly Futures",
    "support": "Support",
    "fees": "Fees",
    "terms": "Terms & Agreement",
    "customer_service": "Customer Service",
    "community": "Community",
    "we-media": "Social Media",
    "quickly_links": "Quick Links",
    "如何在BTCC买卖比特币合约": "Buy Bitcoin (BTC) Futures",
    "如何在BTCC买卖以太坊期货": "Buy Ethereum (ETH) Futures",
    "如何在BTCC买卖莱特币期货": "Buy Litecoin (LTC) Futures",
    "如何在BTCC买卖BCH比特币现金": "Buy Bitcoin Cash (BCH) Futures",
    "如何在BTCC买卖EOS柚子币期货": "Buy EOS Futures",
    "如何在BTCC买卖瑞波币XRP永续合约": "Buy Ripple (XRP) Futures",
    "如何在BTCC买卖艾达币ADA永续合约": "Buy Cardano (ADA) Futures",
    "如何在BTCC买卖恒星币XLM永续合约": "Buy Stellar (XLM) Futures",
    "如何在BTCC买卖达世币永续合约": "Buy Dash Futures",
    "rick_tips": "Risk warning: Digital asset trading is an emerging industry with bright prospects, but it also comes with huge risks as it is a new market. The risk is especially high in leveraged trading since leverage magnifies profits and amplifies risks at the same time. Please make sure you have a thorough understanding of the industry, the leveraged trading models, and the rules of trading before opening a position. Additionally, we strongly recommend that you identify your risk tolerance and only accept the risks you are willing to take. All trading involves risks, so you must be cautious when entering the market.",
    "btcc_slogan": "The world’s longest-running cryptocurrency exchange since 2011",
    "empty": "No available record",
    "copy": "Copy {{name}}",
    "copy_success": "{{name}} copied",
    "copy_fail": "{{name}} copy failed",
    "margin": "Used",
    "free_margin": "Free",
    "free_margin_fullname": "Free Margin",
    "used_margin_fullname": "Used Margin",
    "account_assets": "Assets ({{currency}})",
    "overall_balance": "Total Balance",
    "assets_convert_money": "Total Assets ≈ {{amount}}",
    "used_margin": "Used Margin",
    "net_value": "Account net value",
    "availBalance": "Available Balance",
    "account_net_value": "Total Net Asset Value",
    "float_profit": "Floating P/L",
    "profit": "P/L",
    "recharge": "Deposit",
    "withdraw": "Withdraw fiat currency",
    "otc_buy": "Buy Crypto",
    "otc_sale": "Sell Crypto",
    "withdraw_crypto": "Withdraw",
    "deposit": "Deposit",
    "withdrawal": "Withdrawal",
    "bonus": "Coupons",
    "bonus_amount": "Bonus: {{amount}}",
    "bonus_tips": "Coupons can be used to cover trading fees of USDT-margined futures, and the coverage percentage is subject to the campaign rules and terms. All coupons will be cleared when users make withdrawals. ",
    "assets": "Assets",
    "funds_record": "Transaction details",
    "bouns_record": "Coupon History",
    "history_record": "Trade History",
    "history_pending": "Pending Order History",
    "history_pl": "P/L History",
    "balance": "Balance: {{amount}}",
    "authentication": "Identity Verification",
    "unverified": "Not verified",
    "verified": "Verified",
    "verified_failed": "failed",
    "verified_success": "Verification successful",
    "under_review": "Under review",
    "recertification": "Verify again",
    "reports": "Transaction Report",
    "account_security": "Account Security",
    "logout": "Log out",
    "can_rebate": "Get rebates",
    "download_app": "Download APP",
    "overview": "Home",
    "setting": "Settings",
    "security_setting": "Security Settings",
    "verify_phone": "Mobile Phone Verification",
    "verify_email": "Email Address Verification",
    "verify_google": "Google Authenticator",
    "bind_now": "Bind now",
    "unbind": "Unbind",
    "verify_on": "Enabled",
    "verify_off": "Disabled",
    "modify": "Modify",
    "isee": "Got it",
    "bindMobile": "Bind your mobile number",
    "bindEmail": "Bind your email address",
    "bindGoogle": "Google Authenticator",
    "send_captcha_success": "Code sent successfully",
    "send_captcha_fail": "Failed to send code",
    "cancel": "Cancel",
    "submit": "Confirm",
    "nextStep": "Next",
    "prevStep": "Previous",
    "bind_success": "Binding successful. Withdrawal verification enabled.",
    "verified_on": "Enable withdrawal verification",
    "verified_off": "Disable withdrawal verification",
    "google_step_1": "Download the app",
    "google_step_2": "Add a key",
    "google_step_3": "Get verified",
    "google_step_1_tips": "Download the Google Authenticator app",
    "download_google_authenticator": "Download the app now",
    "google_step_2_tips": "Two steps to complete:\n\n1. Tap the '+' button\niOS: located at the top right corner\nAndroid: located at the bottom right corner\n\n2. Select 'Enter key manually' / 'Enter a setup key'\nFill in your BTCC account email/phone and paste the key.",
    "copy_google_secret": "Copy the key",
    "copy_google_secret_tips": "Please make a backup of your key to avoid losing it.",
    "edit_password": "Change password",
    "edit_password_success": "Password changed successfully",
    "old_password": "Old password",
    "new_password": "New password",
    "confirm_password": "Confirm new password",
    "convert_trade": "Trade Now",
    "mobile_vCode": "SMS verification code",
    "email_vCode": "Email verification code",
    "google_vCode": "Google verification code",
    "remark": "Remark",
    "operation": "Action",
    "delete": "Delete",
    "bank_management": "Bank Account Management",
    "api_management": "API Management",
    "status": "Status",
    "search": "Search",
    "search_empty": "No results found",
    "next_page": "Next",
    "prev_page": "Previous",
    "per_page": "{{amount}} record(s)/page",
    "latest_day": "Today",
    "latest_week": "Last week",
    "latest_month": "Last month",
    "latest_third_month": "Last three months",
    "latest_six_month": "Last six months",
    "latest_year": "Last year",
    "all": "All",
    "date": "Period of time",
    "reports_subtotal": "Subtotal on this page:",
    "reports_total": "Total amount:",
    "income": "Income",
    "expend": "Expenses",
    "assets_detail": "Assets Details",
    "upgrade_now": "Upgrade account now",
    "continue_authentication": "Continue verification",
    "authentication_level": "Your identity verification level:",
    "current_level": "Current level:",
    "account_level": "Account Level:",
    "rights": "Benefits:",
    "see_more": "View more",
    "load_more": "View more",
    "see_detail": "More details",
    "account_riskStatus": "Risk level:",
    "account_status_safe": "Low",
    "account_status_atten": "Medium",
    "account_status_danger": "High",
    "account_status_forceCover": "Forced liquidation",
    "auth_slug": "Exchange for a Better Future",
    "auth_trade_tips": "<0>Log in</0> or <2>register</2> to start trading",
    "upload_img_tips": "Please ensure that the photo is complete and clearly visible and that the document is within the validity period. Please note the size cannot exceed 5MB. Both JPEG and PNG formats are supported.",
    "upload": "Upload",
    "uploading": "Uploading...",
    "deposit_crypto_note": "Notes on depositing crypto",
    "recharge_note": "Notes on making fiat deposits",
    "withdraw_crypto_note": "Withdrawal instructions",
    "withdraw_note": "Fiat currency withdrawal instructions",
    "goback": "Back",
    "warm_tips": "Important",
    "timeSharing": "Time",
    "timeLable1min": "1min",
    "timeLable5min": "5min",
    "timeLable15min": "15min",
    "timeLable30min": "30min",
    "timeLable1hour": "1hour",
    "timeLable2hour": "2hour",
    "timeLable4hour": "4hour",
    "timeLable1day": "1day",
    "timeLable1week": "1week",
    "timeLable1month": "1month",
    "goto_set": "Set up now",
    "goto_bind": "Bind now",
    "skip": "Skip",
    "skip_time": "{{amount}} Skip",
    "skip_step": "Skip this for now",
    "switch_email_valid": "Switch to email address verification",
    "switch_mobile_valid": "Switch to mobile number verification",
    "switch_email": "Switch to email address",
    "switch_mobile": "Switch to mobile number",
    "chart": "Chart",
    "realtime_data": "Real-Time Trading Data",
    "gotIt": "ok",
    "limit_ip": "Current IP address is restricted",
    "limit_ip_recharge": "According to your IP address, your country/region is out of our service area. Unfortunately, we are unable to provide you with the crypto purchase service, but you may use our deposit service as usual.",
    "limit_ip_desc": "According to your IP address, your country/region is out of our service area. Unfortunately, we are unable to provide service for you, and you will not be able to use any functions of the BTCC app.",
    "errors": {
      "LOGOUT_ALREADY_LOGIN": "The account has been logged in on other devices, please log in again",
      "LOGOUT_USER_DISABLE": "Users are not available, if you have any questions, please contact online customer service",
      "LOGOUT_ACCOUNT_DISABLE": "Account is not available, if you have any questions, please contact online customer service",
      "LOGOUT_PASSWORD_CHANGE": "Password has been changed, please login again",
      "LOGIN_EXPIRED": "Login failed, please login again"
    },
    "sorry_title": "Sorry,The page you were looking for failed to access",
    "sorry_tips": "Return to the homepage to see other content",
    "insufficient_available_balance": "Insufficient available balance in the account currently.",
    "captcha_title": "Safety Verification",
    "captcha_desc": "Slide to complete the image",
    "vip_fee": "VIP Fee Discounts",
    "vip_level_slogan": "Your current VIP level: <strong>{{current}}</strong> with a <strong>{{discount}}</strong> discount on transaction fees<br/>Upgrade your account to enjoy a <strong>{{next}}</strong> discount",
    "free_discount": "{{amount}} Discount",
    "trading_usdt": "USDT-Margined Futures Transaction History",
    "trading_inverse": "Coin-Margined Futures Transaction History",
    "weekly_name": "{{symbol}} Weekly {{index}}",
    "daily_name": "{{symbol}} Daily {{index}}",
    "symbol_name": "{{symbol}} Perpetual {{index}}",
    "quarter_name": "{{symbol}} Quarterly {{index}}",
    "next_quarter_name": "{{symbol}} Half-yearly {{index}}",
    "next_weekly_name": "{{symbol}} Bi-weekly {{index}}",
    "account_risks": "Account risks",
    "view_details": "View details",
    "day": "day(s)",
    "hours": "hour(s)",
    "minutes": "min(s)",
    "second": "second",
    "hot_contract_product": "Popular futures",
    "feed_title": "Report an Issue",
    "get_reward": "Report an Issue",
    "notification_unread": "<b>{{amount}}</b> new notification(s)",
    "notification_empty": "No new notifications",
    "clear_all": "Clear all",
    "hide_read": "Hide read notifications",
    "read_all": "Mark all as read",
    "notifications": "Notifications",
    "promotions_tips": "Check out our campaigns to win exciting rewards",
    "choose_country_title": "Country/region of residence",
    "academy_tips": "Learn about blockchain and crypto",
    "coin_news_tips": "The latest trends in the crypto market",
    "affiliate_tips": "Invite friends & get rewarded",
    "referral_program_tips": "Invite friends & get rewarded",
    "announcements_tips": "Find all our official announcements",
    "promotions_center_tips": "Join our campaigns to receive exciting rewards",
    "about_btcc_tips": "Learn about our brand",
    "support_center_tips": "Find our FAQs here",
    "feedback_tips": "Your voice matters to us",
    "buy_crypto_tips": "Buy your favourite coins in seconds",
    "deposit_crypto_tips": "Make instant crypto transfers to your futures account",
    "coin_convert_tips": "Covert your crypto instantly",
    "blog_tips": "Check out our latest news",
    "usd_contract_tips": "Trade futures contracts settled in USDT",
    "currency_contract_tips": "Trade futures contracts settled in cryptocurrency",
    "btcc_updates_tips": "Check out our latest news",
    "vip_fee_tips": "Enjoy different fee discounts based on your VIP level",
    "nav_recommond": "Recommended",
    "step_current": "Step {{amount}}",
    "step_done": "Close",
    "start_guide": "See tutorial",
    "have_read_guide": "Got it. Please don't remind me again.",
    "user_guide": "Beginner's Tutorial",
    "coinfo_market": "Markets",
    "cookies_allow": "We use cookies to personalise content and ads, to provide social media features, and to analyse our traffic. We also share information about your use of our website with our social media, advertising, and analytics partners.",
    "allowed": "I accept",
    "deposit_now": "Deposit now",
    "buy_crypto_now": "Buy Crypto",
    "see_all": "See all",
    "estimated_total": "Estimated Total Value",
    "estimated_futures": "Estimated Futures Value ",
    "estimated_wallet": "Estimated Wallet Value ",
    "unit_billion": "${{amount}} billion",
    "unit_million": "{{amount}} million",
    "successful": "Success",
    "chart_open": "Open:",
    "chart_close": "Close:",
    "chart_high": "High:",
    "chart_low": "Low:",
    "main_indicator": "Main",
    "sub_indicator": "Sub",
    "indicator": "Indicator",
    "crypto_community": "Social",
    "crypto_community_tips": "Share your views on crypto",
    "loading": "Loading...",
    "time_remaining": "{{amount}} left",
    "favorite_crypto": "Favourites",
    "mylike_contract": "You may like",
    "add_to_favourites": "Add to Favourites",
    "contrarian_futures": "Coin-margined futures",
    "popular_contract": "Popular",
    "new_contract": "New",
    "tokenized_commodities": "Tokenized Commodities",
    "tokenized_stocks": "Tokenized Stocks",
    "football": "Fan Token",
    "public_chain": "Blockchain",
    "mainstream": "Top",
    "start_now": "Start now",
    "buy_long": "Buy/Long",
    "sell_short": "Sell/Short",
    "vip_guide": "Upgrade your VIP level",
    "vip_guide_desc": "Get discounts on trading fees & enjoy exclusive privileges.",
    "vip_guide_discount": "Level up your VIP tier to enjoy up to 50% off on trading fees and exclusive privileges!",
    "vip_guide_more": "Check my VIP level",
    "trade_everywhere": "Begin your crypto journey anytime, anywhere",
    "add_custom": "Customise",
    "publish_viewport_success": "Post published",
    "publish_viewport": "Share post",
    "viewport": "Posts",
    "viewport_placeholder": "Share your views...",
    "publish_submit": "Post",
    "topic_placeholder": "Add a topic to your post/article",
    "join_topic": "Add a topic",
    "search_coins": "Search coins/trading pairs",
    "search_history": "Search History",
    "bullish": "Bullish",
    "bearish": "Bearish",
    "keep_watching": "Still watching",
    "you_view": "What's your view on the market?",
    "share": "Share",
    "currency_USD": "USD",
    "currency_KRW": "KRW",
    "currency_JPY": "JPY",
    "currency_CNY": "CNY",
    "currency_TWD": "TWD",
    "go_deposit": "Deposit",
    "hot_contract": "Hot",
    "base_kline": "Basic",
    "announcements_notice": "Announcements",
    "live_chart": "Live Chat",
    "feedback": "Give Us Feedback",
    "contract_specifications": "Contract Specifications",
    "chart_solid_candles": "Solid candles",
    "chart_hollow_candles": "Hollow candles",
    "chart_hollow_rise": "Hollow rise",
    "chart_hollow_fall": "Hollow fall",
    "chart_bars": "Bars",
    "chart_area": "Area",
    "market_buy": "Buy at market price",
    "market_sell": "Sell at market price",
    "vip_benefit_discount": "benefit: Enjoy a {{amount}} fee discount",
    "trading_preference": "Set your preferences",
    "order_reconfirmation": "Order reconfirmation",
    "market_closed": "Market closed",
    "network_online": "Stable connection",
    "network_delay": "Connection delayed",
    "network_delay_tips": "Connection is delayed and not stable. Please switch to a different network and try again.",
    "network_offline": "Network disconnected",
    "network_offline_tips": "Network is disconnected and the data has stopped refreshing. Please switch to a different network and try again.",
    "network_reconnect": "Reconnecting",
    "network_reconnect_tips": "The network connection is not stable and it is reconnecting. Please switch to a different network and try again.",
    "current_entrust": "Open Orders",
    "buy_crypto_desc": "Fund your account with USDT using Visa/Mastercard",
    "deposit_crypto_desc": "Make instant crypto transfers to your futures account",
    "coin_convert_desc": "Convert your assets to different cryptos instantly",
    "demo_trading": "Demo Trading",
    "demo_trading_desc": "Practise trading USDT-margined and coin-margined futures",
    "demo_trading_label": "100,000 USDT virtual fund",
    "contract_guide": "Trading Articles",
    "contract_guide_desc": "Read all the guides about futures trading",
    "futures_desc": "Check the latest updates of the futures market",
    "benefits": "Benefits",
    "promotions_center_label": "Get rewards daily",
    "promotions_center_desc": "Join our campaigns to receive exciting rewards",
    "vip_plan": "VIP Programme",
    "vip_plan_label": "Get discounts",
    "vip_plan_desc": "Become a VIP user to enjoy exclusive privileges ",
    "referral": "Referral",
    "referral_label": "Get unlimited rebates",
    "referral_desc": "Invite friends and receive rewards",
    "announcements_desc": "Find all our official announcements",
    "btcc_updates_desc": "Check out our latest news and feature releases",
    "btcc_security": "BTCC and Security",
    "btcc_security_desc": "Learn about our industry-leading protection",
    "affiliates": "Affiliates",
    "affiliates_desc": "Turn your influence into revenue",
    "news": "News",
    "news_desc": "Read in-depth analysis of crypto news",
    "blog_desc": "Find all the how-tos of our platform",
    "live_chart_desc": "Get your questions answered by our live customer support",
    "btcc_community": "BTCC Community",
    "btcc_community_tips": "Get social with us",
    "learn": "Learn",
    "learn_desc": "Learn about blockchain and crypto",
    "contract_area": "Futures",
    "add_to_favourites_desc": "Add products to your favourites and monitor their prices",
    "share_image_tips": "Save the chart as an image and share on social media",
    "market_cap": "Market Cap",
    "ytd": "YTD",
    "price": "Price",
    "24h_volume": "24-hr Trade Volume",
    "24_open_interest": "24h Open Interest",
    "24_float": "24h Change",
    "embed_iframe": "Embed IFRAME",
    "embed_html": "Embed HTML5",
    "copy_webpage_url": "Copy webpage address",
    "copy_html": "Copy code",
    "share_upper_limit": "You've reached the sharing limit for today. Please share again tomorrow.",
    "coin_to_usd": "{{name}} to USD Chart ({{symbol}})",
    "userinfo": "Profile details",
    "complete_your_profile": "Complete your profile",
    "complete_your_profile_p": "Please input your date of birth and your current country of residence. If the country shown below is not your current country of residence, please update it accordingly.",
    "media_title": "BTCC's official X account",
    "media_btn": "Follow us on X",
    "retry": "try again",
    "subscribe_rss": "English RSS",
    "timeout_reconnect": "Session expired. Please re-connect.",
    "continue_use": "Register Now",
    "timeout_register": "Continue",
    "nodata": "You've reached the end of the page",
    "no_social_feature": "We're working on the Social feature. Stay tuned!",
    "look_more": "Look More",
    "sunday": "Sunday",
    "monday": "Monday",
    "tuseday": "Tuseday",
    "wednesday": "Wednesday",
    "thurseday": "Thurseday",
    "friday": "Friday",
    "saturday": "Saturday",
    "underway": "Current",
    "lever_name": "{{lever}}x",
    "skip_guide": "Skip guide",
    "deposit_guide": "Deposit guide",
    "switch_beginner_mode": "Switch to beginner mode",
    "safety_verify": "Safety Verification",
    "nft": "NFT Marketplace",
    "transfer": "Transfer",
    "return_to_home": "Return to Home",
    "base_verify": "Identity verification",
    "base_verify_empty": "ID not verified",
    "base_verify_pending": "Verifying ID",
    "base_verify_fail": "ID verification failed",
    "faceid_verify_empty": "Facial verification not completed",
    "faceid_verify_pending": "Facial verification in progress",
    "faceid_verify_fail": "Facial verification failed",
    "faceid_verify_done": "Verified",
    "verifing": "Verifying",
    "verify_fail": "failed",
    "faceid_verify": "Facial verification",
    "require_kyc": "Identity Verification Required",
    "require_kyc_desc": "You are required to verify your identity to use this service.",
    "no_payment_methods": "No payment methods are currently available",
    "no_payment_methods_desc": "There is no available provider for the service you are using at the moment.",
    "verify_now": "Verify",
    "under_verify": "Verification under review",
    "view_now": "View",
    "nft_buy": "NFT Airdrop Rewards",
    "nft_buy_desc": "Buy NFTs via payment methods such as Kakaopay to receive airdrop rewards",
    "money_experience": "Exclusive to new users",
    "money_experience_desc": "Get 10055 USDT",
    "hot": "Hot",
    "demo_trading_tips": "Get 100,000 USDT virtual funds for free",
    "limit_times": "Only <span>{{d}}</span> day(s) <span>{{h}}</span>:<span>{{m}}</span>:<span>{{s}}</span> left",
    "live_chat": "Live Chat",
    "address": "Address",
    "forum": "forum",
    "withdrawal_white_list_tips": "Withdrawal address whitelist is enabled. You can only withdraw to your frequently used addresses.",
    "fund_password_safe_tips": "It's more secure to use a fund password.<light>Set fund password.</light>",
    "failed_submit_request": "Failed to submit request",
    "position_mode_set": "Position mode set",
    "password_update_limit": "For the safety of your funds, withdrawals will not be possible for 24 hours after you change your security settings.",
    "reason_rejection": "Reason of rejection",
    "not_yet": "Maybe later",
    "transfer_tips": "You can transfer {{amount}} to your Futures account",
    "convert_tips": "You can convert {{amount}}",
    "fiat_recharge": "Fiat deposit",
    "copy_trading": "Copy Trading",
    "copy_trading_desc": "Follow the world's top traders and copy their strategies",
    "avatar": "Profile picture",
    "weekly": "Weekly",
    "daily": "Daily",
    "quarter": "Quarterly",
    "next_quarter": "Half-yearly",
    "next_weekly": "Bi-weekly",
    "perpetual": "Perpetual",
    "details": "Details",
    "language": "Language",
    "currency": "Currency",
    "share_title": "🙋🏻‍♂️ Join me at #BTCCexchange, a safe and reliable platform for crypto trading. \n\nRegister for free & earn rewards as you register, deposit, and trade! \n\n👉🏻 Sign up with my referral code: {{code}} ",
    "download_trading_history": "Download Trading History",
    "download_trading_history_desc": "Each user is limited to 10 exports per month.",
    "set_time_frame": "Set time frame",
    "last_24_hrs": "Last 24 hrs",
    "last_7_days": "Last 7 days",
    "last_30_days": "Last 30 days",
    "custom": "Custom",
    "export_data_for": "Export data for",
    "file_type": "File type",
    "export_history": "Export History",
    "download_links_expire_in_7_d": "Download links expire in 7 days.",
    "period": "Period",
    "requested_on": "Requested on",
    "report_status": "Status",
    "report_processing": "Processing",
    "report_failed": "Failed",
    "report_complete": "Complete",
    "report_expired": "Expired",
    "export_now_remain": "Export Now",
    "export_now_remain_count": " ({{count}} Remaining)",
    "last_login": "Last Login",
    "welcome_back": "Welcome back",
    "link_email": "Link email",
    "bind_email_title": "Link email address",
    "bind_email_tips": "Please link your email address before using this transfer method.",
    "third_party_accounts": "Third Party Accounts",
    "metaMask_wallet": "MetaMask wallet",
    "add": "Add",
    "connect": "Connect",
    "disconnect": "Disconnect",
    "wallet_address": "Wallet address",
    "connect_metaMask_wallet": "Connect MetaMask wallet",
    "connect_metaMask_wallet_tips": "Your MetaMask wallet will be connected to your BTCC account.",
    "disconnect_metaMask_wallet": "Disconnect MetaMask wallet",
    "disconnect_metaMask_wallet_tips": "Your MetaMask wallet is now connected to your BTCC account.",
    "connected_successfully": "Connected successfully",
    "disconnected_successfully": "Disconnected successfully",
    "install": "Install",
    "metamask_install_tips": "MetaMask has not been installed yet. Please install MetaMask on your browser and refresh this page.",
    "transfer_amount": "Transfer amount",
    "metaMask_wallet_address": "MetaMask wallet address",
    "btcc_deposit_address": "BTCC deposit address",
    "metamask_transfer": "Transfer",
    "start_date": "Start Date",
    "end_date": "End Date",
    "please_enter": "Please enter",
    "please_enter_transfer_amount": "Please enter the transfer amount",
    "transfer_successful": "Transfer successful",
    "transfer_failed": "Transfer failed",
    "metamask_faster_login": "For faster log in, deposit & withdrawal",
    "third_login_bind_error": "The entered phone number/email/third party account is already linked to another account. Please enter new credentials.",
    "link_wallet": "Link wallet",
    "link_wallet_desc_01": "Link your MetaMask wallet to obtain an ETH address. Please select the Ethereum Main Network.",
    "link_wallet_desc_02": "If your wallet fails to link or is unresponsive, please close MetaMask or your browser and try again.",
    "link_wallet_btn": "Link Now",
    "dark_mode": "Dark Mode",
    "dark_mode_tips": "New theme available",
    "setting_main_coupon": "My coupons",
    "coupon_amount": "Amount",
    "trade_account": "Futures Account",
    "inner_account": "Wallet Account",
    "limit_service": "Based on your IP address, our services are currently unavailable in your region due to local laws and regulations. We apologize for any inconvenience. ",
    "limit_service_slogan": "BTCC is currently unavailable in your region.",
    "limit_service_title": "BTCC’s Service is Restricted in Hong Kong",
    "limit_service_desc": "Based on your IP address, you are in {Hong Kong}, where we currently cannot offer our services or allow registration due to local laws, regulations, or policies. Please access BTCC from a supported region or contact support for assistance. We apologize for any inconvenience and appreciate your understanding.",
    "limit_service_btn": "I understand"


  },

  bwtc:{
    "bwtc_title": "BTCC World Trading Competition 2024",
    "bwtc_slogan": "Total Prize Pool: 10,000,000 USDT",
    "bwtc_share": "Share",
    "bwtc_join": "Register now",
    "bwtc_personal_title": "Individual Trading Competition",
    "bwtc_personal_desc": "Compete and share a prize pool of 1,400,000 USDT. Each participant can earn rewards of up to 26,700 USDT per month!",
    "bwtc_pool_daily": "Daily Prize Pool",
    "bwtc_pool_week": "Weekly Prize Pool",
    "bwtc_pool_month": "Monthly Prize Pool",
    "bwtc_pool_start": "Time until prize pool opens: {{day}}d {{hour}}h {{minute}}m {{second}}s",
    "bwtc_peroid": "Round {{amount}}",
    "bwtc_status_inprogress": "Ongoing",
    "bwtc_status_over": "Concluded",
    "bwtc_total_tradeamount": "Total Trading Volume (USDT)",
    "bwtc_lock_amount": "Unlocked Prize Pool (USDT)",
    "bwtc_pool": "Prize Pool (USDT)",
    "bwtc_login": "<b>Log in</b> to view my rewards",
    "bwtc_my_ranking": "My Ranking",
    "bwtc_my_daily_tradeamount": "My Trading Volume of the Day",
    "bwtc_my_week_tradeamount": "My Trading Volume of the Week",
    "bwtc_my_month_tradeamount": "My Trading Volume of the Month",
    "bwtc_my_awardamount": "Estimated Reward",
    "bwtc_ranking_title": "Leaderboard",
    "bwtc_prize_pool": "Prize pool in real time",
    "bwtc_ranking": "Ranking",
    "bwtc_uid": "UID",
    "bwtc_tradeamount": "Trading volume (USDT)",
    "bwtc_awardamount": "Estimated reward (USDT)",
    "bwtc_update_frequency": "Updated every 1 hours",
    "bwtc_rules_title": "Rules",
    "bwtc_daily_rules_desc": "Daily Prize Pool: Initial pool of 100 USDT, with an additional 100 USDT unlocked for every 1,000,000 USDT traded by all participants, up to a maximum of 5,000 USDT.",
    "bwtc_week_rules_desc": "Weekly Prize Pool: Initial pool of 400 USDT, with an additional 400 USDT unlocked for every 4,000,000 USDT traded by all participants, up to a maximum of 15,000 USDT.",
    "bwtc_month_rules_desc": "Monthly Prize Pool: Initial pool of 1,000 USDT, with an additional 1,000 USDT unlocked for every 10,000,000 USDT traded by all participants, up to a maximum of 30,000 USDT.",
    "bwtc_pool_end": "Time until the competition ends: {{day}}d {{hour}}h {{minute}}m {{second}}s",
    "bwtc_registered": "Registered",
    "bwtc_join_success": "Registration successful",
    "bwtc_submit": "Confirm",
    "bwtc_join_fail": "Registration failed. Please refresh and try again.",
    "bwtc_isee": "Got it",
    "bwtc_coming_soon": "The event is about to start, so stay tuned",
    "bwtc_individual": "Individual",
    "bwtc_copytrading": "Copy Trading",
    "bwtc_referral": "Referral",
    "bwtc_share_title": "Join me in sharing a $10M USDT prize pool! Prizes are available daily. Also, a limited-time 10,055 USDT welcome reward is now up for grabs!",
    "bwtc_share_qrcode": "Scan to view campaign details"
  },
  
  RegisterThirdParty:{
    从注册开始: 'Create Your Account',
    邮箱注册: 'Email',
    输入电子邮箱: 'Please enter your email address',
    设置登录密码: 'Set up a password',
    手机注册: 'Mobile',
    手机号: 'Mobile',
    邮箱: 'Email',
    密码: 'Password',
    邀请码: 'Referral code (optional)',
    下一步: "Next",
    协议: "As you proceed to complete your registration, you agree to our User Agreement,Privacy Policy and Risk Disclosure We will never divulge any of your personal information without your prior consent.",
    綁定電子信箱: "Bind your email address",
    signup: "Sign Up",
    NextStep:"Next Step",
  },

  signup: {
    邮箱注册: "Email Registration",
    手机注册: "Mobile Registration",
    电子邮箱: "Email Address",
    手机号码: "Cell Phone Number",
    验证码: "Verification Code",
    获取验证码: "Get Verification Code",
    设置密码: "Set Password",
    立即注册: "Register Now ",
    我已阅读并同意BTCC的: "I have read and agree with"
  },

  countryList: {
    ISO_3166_704: "Vietnam",
    ISO_3166_156: "China",
    ISO_3166_344: "Hong Kong (China)",
    ISO_3166_158: "Taiwan",
    ISO_3166_446: "Macau",
    ISO_3166_004: "Afghanistan",
    ISO_3166_008: "Albania",
    ISO_3166_020: "Andorra",
    ISO_3166_024: "Angola",
    ISO_3166_010: "Antarctica",
    ISO_3166_028: "Antigua and Barbuda",
    ISO_3166_032: "Argentina",
    ISO_3166_051: "Armenia",
    ISO_3166_533: "Aruba",
    ISO_3166_036: "Australia",
    ISO_3166_040: "Austria",
    ISO_3166_031: "Azerbaijan",
    ISO_3166_784: "United Arab Emirates",
    ISO_3166_044: "Bahamas",
    ISO_3166_048: "Bahrain",
    ISO_3166_050: "Bangladesh",
    ISO_3166_052: "Barbados",
    ISO_3166_112: "Belarus",
    ISO_3166_084: "Belize",
    ISO_3166_056: "Belgium",
    ISO_3166_204: "Benin",
    ISO_3166_060: "Bermuda Islands",
    ISO_3166_064: "Bhutan",
    ISO_3166_068: "Bolivia",
    ISO_3166_070: "Bosnia and Herzegovina",
    ISO_3166_072: "Botswana",
    ISO_3166_076: "Brazil",
    ISO_3166_096: "Brunei",
    ISO_3166_100: "Bulgaria",
    ISO_3166_854: "Burkina Faso",
    ISO_3166_108: "Burundi",
    ISO_3166_120: "Cameroon",
    ISO_3166_124: "Canada",
    ISO_3166_140: "Central African Republic",
    ISO_3166_152: "Chile",
    ISO_3166_162: "Christmas Island",
    ISO_3166_166: "Coco Islands",
    ISO_3166_170: "Colombia",
    ISO_3166_756: "Switzerland",
    ISO_3166_178: "Congo",
    ISO_3166_184: "Cook Island",
    ISO_3166_188: "Costa Rica",
    ISO_3166_384: "Ivory Coast",
    ISO_3166_192: "Cuba",
    ISO_3166_196: "Cyprus",
    ISO_3166_203: "Czech Republic",
    ISO_3166_208: "Denmark",
    ISO_3166_262: "Djibouti",
    ISO_3166_276: "Germany",
    ISO_3166_214: "Dominican Republic",
    ISO_3166_012: "Algeria",
    ISO_3166_218: "Ecuador",
    ISO_3166_818: "Egypt",
    ISO_3166_732: "Western Sahara",
    ISO_3166_724: "Spain",
    ISO_3166_233: "Estonia",

    ISO_3166_231: "Ethiopia",
    ISO_3166_242: "Fiji",
    ISO_3166_238: "Falkland Islands",
    ISO_3166_246: "Finland",
    ISO_3166_250: "France",
    ISO_3166_583: "Micronesia",
    ISO_3166_266: "Gabon",
    ISO_3166_226: "Equatorial Guinea",
    ISO_3166_254: "French Guyana",
    ISO_3166_270: "Gambia",
    ISO_3166_239: "Georgia",
    ISO_3166_288: "Ghana",
    ISO_3166_292: "Gibraltar",
    ISO_3166_300: "Greece",
    ISO_3166_304: "Greenland",
    ISO_3166_826: "United Kingdom",
    ISO_3166_308: "Grenada",
    ISO_3166_312: "Guadeloupe",
    ISO_3166_316: "Guam",
    ISO_3166_320: "Guatemala",
    ISO_3166_324: "Guinea",
    ISO_3166_624: "Guinea Bissau",
    ISO_3166_328: "Guyana",
    ISO_3166_191: "Croatia",
    ISO_3166_332: "Haiti",
    ISO_3166_340: "Honduras",
    ISO_3166_348: "Hungary",
    ISO_3166_352: "Iceland",
    ISO_3166_356: "India",
    ISO_3166_360: "Indonesia",
    ISO_3166_364: "Iran",
    ISO_3166_368: "Iraq",
    ISO_3166_086: "British Indian Ocean Territory",
    ISO_3166_372: "Ireland",
    ISO_3166_376: "Israel",
    ISO_3166_380: "Italy",
    ISO_3166_388: "Jamaica",
    ISO_3166_392: "Japan",
    ISO_3166_400: "Jordan",
    ISO_3166_398: "Kazakhstan",
    ISO_3166_404: "Kenya",
    ISO_3166_296: "Kiribas",
    ISO_3166_408: "North Korea",
    ISO_3166_410: "Korea",
    ISO_3166_116: "Cambodia",
    ISO_3166_174: "Comoros",
    ISO_3166_414: "Kuwait",
    ISO_3166_417: "Kyrgyzstan",
    ISO_3166_136: "Cayman Islands",
    ISO_3166_418: "In stock",
    ISO_3166_144: "Sri Lanka",
    ISO_3166_428: "Latvia",
    ISO_3166_422: "Lebanon",
    ISO_3166_426: "Lesotho",
    ISO_3166_430: "Liberia",
    ISO_3166_434: "Libya",
    ISO_3166_438: "Liechtenstein",
    ISO_3166_440: "Lithuania",
    ISO_3166_442: "Luxembourg",
    ISO_3166_450: "Madagascar",
    ISO_3166_454: "Malawi",
    ISO_3166_458: "Malaysia",
    ISO_3166_462: "Maldives",
    ISO_3166_466: "Mali",
    ISO_3166_470: "Malta",
    ISO_3166_584: "Marshall Islands",
    ISO_3166_478: "Mauritania",
    ISO_3166_480: "Mauritius",
    ISO_3166_484: "Mexico",
    ISO_3166_498: "Moldova",
    ISO_3166_492: "Monaco",
    ISO_3166_496: "Mongolia",
    ISO_3166_504: "Morocco",
    ISO_3166_508: "Mozambique",
    ISO_3166_104: "Myanmar",
    ISO_3166_516: "Namibia",
    ISO_3166_520: "Nauru",
    ISO_3166_524: "Nepal",
    ISO_3166_528: "Netherlands",
    ISO_3166_540: "New Caledonia",
    ISO_3166_554: "New Zealand",
    ISO_3166_558: "Nicaragua",
    ISO_3166_562: "Niger",
    ISO_3166_566: "Nigeria",
    ISO_3166_570: "Niue Island",
    ISO_3166_578: "Norway",
    ISO_3166_512: "Oman",
    ISO_3166_586: "Pakistan",
    ISO_3166_260: "French Polynesia",
    ISO_3166_585: "Palau",
    ISO_3166_591: "Panama",
    ISO_3166_598: "Papua New Guinea",
    ISO_3166_600: "Paraguay",
    ISO_3166_604: "Peru",
    ISO_3166_608: "Philippines",
    ISO_3166_612: "Pitcairn Islands",
    ISO_3166_616: "Poland",
    ISO_3166_620: "Portugal",
    ISO_3166_630: "Puerto Rico",
    ISO_3166_634: "Qatar",
    ISO_3166_642: "Romania",
    ISO_3166_643: "Russia",
    ISO_3166_646: "Rwanda",
    ISO_3166_222: "El Salvador",
    ISO_3166_654: "St. Helena",
    ISO_3166_674: "San Marino",
    ISO_3166_678: "Sao Tome and Principe",
    ISO_3166_682: "Saudi Arabia",
    ISO_3166_686: "Senegal",
    ISO_3166_690: "Seychelles",
    ISO_3166_694: "Sierra Leone",
    ISO_3166_702: "Singapore",
    ISO_3166_703: "Slovakia",
    ISO_3166_705: "Slovenia",
    ISO_3166_090: "Solomon Islands",
    ISO_3166_706: "Somalia",
    ISO_3166_736: "Sudan",
    ISO_3166_740: "Suriname",
    ISO_3166_748: "Swaziland",
    ISO_3166_752: "Sweden",
    ISO_3166_760: "Syria",
    ISO_3166_810: "Soviet Union",
    ISO_3166_148: "Chad",
    ISO_3166_762: "Tajikistan",
    ISO_3166_834: "Tanzania",
    ISO_3166_764: "Thailand",
    ISO_3166_768: "Togo",
    ISO_3166_772: "Tokla",
    ISO_3166_776: "Tonga",
    ISO_3166_780: "Trinidad and Tobago",
    ISO_3166_788: "Tunisia",
    ISO_3166_792: "Turkey",
    ISO_3166_626: "East Timor",
    ISO_3166_795: "Turkmenistan",
    ISO_3166_798: "Tuvalu",
    ISO_3166_800: "Uganda",
    ISO_3166_804: "Ukraine",
    ISO_3166_840: "United States",
    ISO_3166_858: "Uruguay",
    ISO_3166_860: "Uzbekistan",
    ISO_3166_336: "Vatican",
    ISO_3166_862: "Venezuela",
    ISO_3166_882: "Western Samoa",
    ISO_3166_887: "Yemen",
    ISO_3166_891: "Yugoslavia",
    ISO_3166_710: "South Africa",
    ISO_3166_180: "Zaire",
    ISO_3166_894: "Zambia",
    ISO_3166_716: "Zimbabwe",
    other: "Other",
    ISO_3166_074: "Boway Island",
  },
  "已有账户？": "Already have an account？",
  立即登录: "Login now.",
  立即注册: "Register Now",
  发送验证码成功:
    "Verification code sent successfully！In case a verification code doesn't arrive, check your Spam/Junk folder. ",
  search: "Search",
};
