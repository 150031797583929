/* eslint-disable import/no-anonymous-default-export */
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const Signup_bouns13 = lazy(() => import('./pages/signup-bouns13'))
const RV = lazy(() => import('./pages/rv'))
const BitcoinHalvingBTCC = lazy(() => import('./pages/bitcoin-halving-btcc'))
const Bwtc = lazy(() => import('./pages/bwtc/index.jsx'))
// const Deposit = lazy(() => import('./pages/deposit'))
const Win_Funds = lazy(() => import('./pages/win-funds'))
const Trading_Voucher = lazy(() => import('./pages/trading-voucher'))
const Win_Funds_KR = lazy(() => import('./pages/win-funds-kr'))
const Win_Funds_TW = lazy(() => import('./pages/win-funds-tw'))
const Exchange = lazy(() => import('./pages/exchange'))
const FreeUSDT = lazy(() => import('./pages/FreeUSDT'))
const FreeEasy = lazy(() => import('./pages/FreeEasy'))
const Bitcoin = lazy(() => import('./pages/bitcoin'))
const DepositBonus = lazy(() => import('./pages/depositBonus'))
const Wealth99 = lazy(() => import('./pages/wealth99'))
const CopyTrading = lazy(() => import('./pages/copytrading'))
const TwdBtc = lazy(() => import('./pages/TWDBTC'))
const BTCTWD = lazy(() => import('./pages/BTCTWD'))
const Transactionfee = lazy(() => import('./pages/transactionfee'))
const CopyTradingTw = lazy(() => import('./pages/copytradingTW'))
const OTC21Century = lazy(() => import('./pages/OTC21Century'))
const CopyTradingKR = lazy(() => import('./pages/copytradingKR'))
const CryptoFutures = lazy(() => import('./pages/crypto-futures'))
const CryptoFutures_af = lazy(() => import('./pages/crypto-futures-af'))
const Compensation = lazy(() => import('./pages/compensation'))
const Googlelp = lazy(() => import('./pages/googlelp'))
const Share = lazy(() => import('./pages/share'))
const Cryptodeposit_kr = lazy(() => import('./pages/cryptodeposit_kr'))
const Cryptodeposit2_kr = lazy(() => import('./pages/cryptodeposit2_kr'))
const Cryptodeposit_kr_mo = lazy(() => import('./pages/cryptodeposit_kr_mo'))
const Cryptodeposit_kr_moapk = lazy(() => import('./pages/cryptodeposit_kr_moapk'))
const Memecoin = lazy(() => import('./pages/memecoin'))
const Cryptodeposit_kr_adj = lazy(() => import('./pages/cryptodeposit_kr_adj'))
const Xmas = lazy(() => import('./pages/Xmas'))
const BBactivity = lazy(()=> import('./pages/BBactivity'))
const CopyTrading_af = lazy(() => import('./pages/copytrading_af'))
const CopyTrading_reg = lazy(() => import('./pages/copytrading_reg'))
const CopyTrading_reg_reg = lazy(() => import('./pages/copytrading_reg/register'))
const Privacy = lazy(() => import('./pages/Privacy'))
const CopyTradingTwAf = lazy(() => import('./pages/copytradingTWAF'))
const Win_Funds_TWaf = lazy(() => import('./pages/win-funds-twAF'))
const Win_Funds_TW2 = lazy(() => import('./pages/win-funds-tw2'))
const CopyTrading_af_moloco = lazy(() => import('./pages/copytrading_af_moloco'))
const Cryptodepositcoin = lazy(()=>import('./pages/cryptodepositcoin'))
const CryptodepositcoinMo = lazy(()=>import('./pages/cryptodepositcoin-mo'))
const CopyTradingRu = lazy(() => import('./pages/copytradingRU'))
const Usdtofficeup = lazy(() => import('./pages/usdtofficeup/index.jsx'))
const CryptodepositcoinMoapk = lazy(()=>import('./pages/cryptodepositcoin-moapk'))
const Officeegp = lazy(()=>import('./pages/officeegp'))
const Officeegpmolo = lazy(()=>import('./pages/officeegpmolo'))
const Usdtofficeup_moloc = lazy(() => import('./pages/usdtofficeup/moloc/index.jsx'))
export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={< ></>}>
          <Route exact path="/" component={Index} />
          <Route path="/signup-bouns13/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Signup_bouns13} />
          <Route path="/RV/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={RV} />
          {/* <Route  path="/deposit" component={Deposit} /> */}
          <Route path="/bitcoin-halving-btcc/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={BitcoinHalvingBTCC} />
          <Route path="/campaign-bwtc/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Bwtc} />
          <Route exact path="/win-funds" component={Win_Funds} />
          <Route exact path="/trading-voucher" component={Trading_Voucher} />
          <Route exact path="/win-funds-kr" component={Win_Funds_KR} />
          <Route exact path="/win-funds-tw" component={Win_Funds_TW} />
          <Route exact path="/exchange" component={Exchange} />
          <Route exact path="/FreeUSDT" component={FreeUSDT} />
          <Route exact path="/FreeEasy" component={FreeEasy} />
          <Route exact path="/bitcoin" component={Bitcoin} />
          <Route exact path="/1000U" component={DepositBonus} />
          <Route exact path="/wealth99" component={Wealth99} />
          <Route exact path="/copytrading" component={CopyTrading} />
          <Route exact path="/TWDBTC" component={TwdBtc} />
          <Route exact path="/BTCTWD" component={BTCTWD} />
          <Route path="/transactionfee" component={Transactionfee} />
          <Route exact path="/copytradingTW" component={CopyTradingTw} />
          <Route exact path="/OTC21Century" component={OTC21Century} />
          <Route exact path="/copytradingKR" component={CopyTradingKR} />
          <Route exact path="/crypto-futures" component={CryptoFutures} />
          <Route exact path="/crypto-futures-af" component={CryptoFutures_af} />
          <Route exact path="/compensation" component={Compensation} />
          <Route exact path="/googlelp" component={Googlelp} />
          <Route exact path="/share" component={Share} />
          <Route exact path="/cryptodeposit-kr" component={Cryptodeposit_kr} />
          <Route exact path="/cryptodeposit2-kr" component={Cryptodeposit2_kr} />
          <Route exact path="/cryptodeposit-kr-mo" component={Cryptodeposit_kr_mo} />
          <Route exact path="/cryptodeposit-kr-moapk" component={Cryptodeposit_kr_moapk} />
          <Route exact path="/memecoin" component={Memecoin} />
          <Route exact path="/cryptodeposit-kr-adj" component={Cryptodeposit_kr_adj} />
          <Route exact path="/Xmas" component={Xmas} />
          <Route exact path="/BBactivity" component={BBactivity} />
          <Route exact path="/copytrading-af" component={CopyTrading_af} />
          <Route exact path="/copytrading-reg" component={CopyTrading_reg} />
          <Route exact path="/copytrading-register" component={CopyTrading_reg_reg} />
          <Route exact path="/Privacy" component={Privacy} />
          <Route exact path="/copytradingTWAF" component={CopyTradingTwAf} />
          <Route exact path="/win-funds-twAF" component={Win_Funds_TWaf} />
          <Route exact path="/win-funds-tw2" component={Win_Funds_TW2} />
          <Route exact path="/copytrading-af-moloco" component={CopyTrading_af_moloco} />
          <Route exact path="/cryptodepositcoin" component={Cryptodepositcoin} />
          <Route exact path="/cryptodepositcoin-mo" component={CryptodepositcoinMo} />
          <Route exact path="/cryptodepositcoin-coinness" component={CryptodepositcoinMo} />
          <Route exact path="/copytrading-RUS" component={CopyTradingRu} />
          <Route exact path="/usdtofficeup" component={Usdtofficeup} />
          <Route exact path="/cryptodepositcoin-moapk" component={CryptodepositcoinMoapk} />
          <Route exact path="/officeegp" component={Officeegp} /> 
          <Route exact path="/officeegpmolo" component={Officeegpmolo} />
          <Route exact path="/usdtofficeup/moloc" component={Usdtofficeup_moloc} />
          
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};
